<template>
  <div class="loading-spinner">
    <h5>{{ text }}</h5>
    <div class="outer-spinner">
      <div class="inner-spinner"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LoadingSpinner",
  props: {
    text: {
      type: String,
      default: "Loading..."
    }
  }
};
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.loading-spinner {
  color: var(--title-color);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.outer-spinner {
  margin: 20px auto;
  display: flex;
  width: 70px;
  height: 70px;
  border: 6px solid transparent;
  border-top-color: #58595b;
  border-bottom-color: #58595b;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  animation: spin 2s cubic-bezier(0.68, -0.55, 0.265, 1.55) infinite forwards;
}

.inner-spinner {
  width: 50px;
  height: 50px;
  border: 6px solid transparent;
  border-left: 6px solid #47a3b9;
  border-right: 6px solid #47a3b9;
  border-radius: 50%;
  animation: spin 2s cubic-bezier(0.68, -0.55, 0.265, 1.55) infinite forwards;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(720deg);
  }
}
</style>
