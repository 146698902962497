<template>
  <div class="home-page" id="home-page">
    <div class="toast-container">
      <div class="notification" ref="notification">
        <header class="notification__header">
          <strong>New quotes!</strong>
          <button class="notification__button" @click="closeNotification">
            X
          </button>
        </header>
        <div class="notification__body">There are new quotes available!</div>
      </div>
    </div>
    <audio
      src="../assets/notification.mp3"
      ref="notificationSound"
      allow="autoplay"
    ></audio>
    <template v-if="isLoading">
      <LoadingSpinner text="Loading Charlie..." />
    </template>
    <template v-else>
      <div class="home-title">
        <h1>Welcome to Charlie!</h1>
        <!-- <img class="logo-img" src="../assets/tai-logo.png" alt="Tai-logo" /> -->
        <img
          class="logo-img"
          src="../assets/Logo-Hypercubes.png"
          alt="hypercubes-logo"
        />
      </div>
      <div class="home-count-quotes">
        <h4 v-if="countQuotes > 1">
          There are {{ countQuotes }} quotes available
        </h4>
        <h4 v-if="countQuotes == 1">
          There is {{ countQuotes }} quote available
        </h4>
        <h4 v-if="countQuotes == 0">There are no quotes available</h4>
      </div>
      <div class="content">
        <OptionButton
          title="Refresh quotes"
          @click.native="refreshPage"
        /><br />
      </div>
    </template>
  </div>
</template>

<script>
import tmsConfig from "@/utils/tmsConfig";
import * as api from "@/api/services";
import LoadingSpinner from "@/components/LoadingSpinner";
import OptionButton from "@/components/OptionButton";
/* import LogoPanel from "@/components/LogoPanel"; */
import { mapState } from "vuex";
export default {
  name: "LoginPage",
  components: {
    LoadingSpinner,
    OptionButton
    /* LogoPanel */
  },
  data: function() {
    return {
      isLoading: true,
      tmsLink: tmsConfig.tmsLink,
      tmsLogo: tmsConfig.tmsLoginLogo,
      countQuotes: 0,
      notificationSound: null
    };
  },
  computed: {
    ...mapState({
      countControl: (state) => state.emailDetails.countPeticionControl
    })
  },
  watch: {
    countControl() {
      this.getCountQuotes();
    }
  },
  methods: {
    showNotification() {
      this.$refs.notification.style.visibility = "visible";
      setTimeout(() => {
        this.$refs.notification.style.visibility = "hidden";
      }, 5000);
    },
    assignEmails: async function() {
      try {
        await api.assignEmails(10);
        const label = document.querySelector(".response");
        label.innerHTML = "Emails brought successfuly";
        label.classList.add("visible");
      } catch (err) {
        console.error(err.message);
      }
    },
    closeNotification() {
      this.$refs.notification.style.visibility = "hidden";
    },
    async getCountQuotes() {
      let params = {};
      let statusList = "?";
      let arrayStatus = [4, 5];
      arrayStatus.forEach((status) => {
        statusList += "status_list=" + status + "&";
      });
      params.user_id = -1;
      params.status_list = statusList;
      let response = await this.$store.dispatch(
        "emailDetails/getCountQuotes",
        params
      );
      this.countQuotes = response.data.count;
      this.playNotificationControl();
    },
    refreshPage() {
      location.reload();
    },
    playAudio() {
      this.notificationSound.play();
    },
    playNotificationControl() {
      let lastCountQuote = localStorage.getItem("countQuotes");
      if (this.countQuotes > lastCountQuote) {
        this.showNotification();
        this.playAudio();
      }
      localStorage.setItem("countQuotes", this.countQuotes);
    },
    /*  refreshPage() {
      location.reload();
    }, */
    sendMessage() {
      let sebas = { sebas: "sebas" };
      this.socket.emit("test_server", sebas);
    }
  },
  created() {
    this.getCountQuotes();
    this.$store.commit("user/tokenValidation");
    this.$store.dispatch("emailDetails/notificationsSocket");
  },
  mounted: function() {
    this.isLoading = false;
    this.notificationSound = this.$refs.notificationSound;
  },
  destroyed() {
    this.$store.commit("emailDetails/setCloseNotificationSocket");
  }
};
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.home-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  overflow: hidden;
}

.home-title {
  margin: 0 auto;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  h1 {
    font-weight: bold;
    color: var(--title-color);
  }
}
.home-count-quotes {
  color: var(--text-color);
}
.logo-img {
  width: 14rem;
  margin: 0.313rem;
}

.content {
  margin-top: 30px;
}
.option-button {
  margin: 5px;
}

.response {
  display: none;
  margin: 10px 0;
  background-color: #fff;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 3px 3px #ccc;

  &.visible {
    display: inline-block;
  }
}

.toast-container {
  position: absolute;
  bottom: 30px;
  right: 50px;
}

.notification {
  visibility: hidden;
  flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgb(0 0 0 / 10%);
  border-radius: 0.25rem;
  &__header {
    position: relative;
    display: flex;
    -ms-flex-align: center;
    -webkit-box-align: center;
    align-items: center;
    padding: 0.5rem 0.75rem;
    color: #6c757d;
    background-color: rgba(255, 255, 255, 0.85);
    background-clip: padding-box;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    border-top-left-radius: calc(0.25rem - 1px);
    border-top-right-radius: calc(0.25rem - 1px);
  }
  &__body {
    padding: 1.1rem 0.75rem;
    color: #811c0e;
    font-weight: 450;
    text-align: left;
  }
  &__button {
    position: absolute;
    top: 8px;
    right: 10px;
    transform: scale(1.3, 1);
    background: none;
    border: none;
    cursor: pointer;
  }
}

/* MEDIA QUERIES: RESPONSIVE DESIGN */
@media (max-width: 400px) {
  .home-page {
    height: 91vh;
  }
}
</style>
