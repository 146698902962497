<template>
  <div class="the-login">
    <header class="login-header">
      <h1 class="login-header__title">Welcome to CharlieBot</h1>
      <h3>{{ tmsLoginMessage }}</h3>
    </header>
    <form class="login-form" method="post">
      <div v-if="!auth">
        <input
          class="login-input"
          type="text"
          name="username"
          placeholder="Username"
          required
          v-model="username"
        />
        <input
          class="login-input"
          type="password"
          name="password"
          placeholder="Password"
          required
          v-model="password"
        />
        <b-overlay
          :show="busy"
          rounded
          opacity="0.6"
          spinner-small
          class="d-inline-block"
        >
          <button class="login-button" type="submit" @click="login($event)">
            login
          </button>
        </b-overlay>
        <label class="login-response"></label>
      </div>
      <div v-else>
        <button class="login-button" type="submit" @click="logout($event)">
          logout
        </button>
        <button
          class="login-button"
          @click="
            $event.preventDefault();
            startApp();
          "
        >
          go charlie
        </button>
        <label>You are already logged in</label>
      </div>
    </form>
  </div>
</template>

<script>
import * as auth from "@/middlewares/auth";
import tmsConfig from "@/utils/tmsConfig";
import { mapState, mapActions } from "vuex";
import Swal from "sweetalert2";
export default {
  name: "TheLogin",
  data: function() {
    return {
      tmsLoginLogo: tmsConfig.tmsLoginLogo,
      tmsLink: tmsConfig.tmsLink,
      tmsLoginMessage: tmsConfig.tmsLoginMessage,
      busy: false,
      username: "",
      password: ""
    };
  },
  computed: {
    ...mapState("user", ["auth"])
  },
  methods: {
    ...mapActions("user", ["loginUser", "logoutUser"]),
    startApp: function() {
      this.loginUser();
      this.$router.push({ name: "home" });
    },
    login: async function() {
      this.$router.push({ name: "home" });
      this.busy = true;
      event.preventDefault();
      const loginResponse = this.$el.querySelector(".login-response");
      const form = this.$el.querySelector(".login-form");
      const formData = new FormData(form);
      try {
        await auth.login(formData);
        loginResponse.innerHTML = "Logged Successfully";
        loginResponse.style.color = "green";
        this.startApp();
        this.busy = false;
      } catch (err) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "incorrect username or password"
        });
        this.username = "";
        this.password = "";
        this.busy = false;
        const errorCode = err.response ? err.response.status : 500;
        let errorMessage = err.response ? err.response.statusText : err.message;
        if (errorCode === 400) errorMessage = err.response.data.detail;
        if (errorCode === 422) errorMessage = "Required fields empty";
        loginResponse.innerHTML = errorMessage;
        loginResponse.style.color = "red";
      }
    },
    isAuth: async function() {
      return await auth.isAuth();
    },
    logout: function(event) {
      event.preventDefault();
      this.logoutUser();
      auth.logout();
      window.location.reload();
    }
  }
};
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.the-login {
  background-color: #ffffff;
  border-radius: 15px;
  padding: 10px 20px;
  height: auto;
  margin: 30px 30px 30px 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-header {
  font-weight: bold;
  margin: 30px 30px 10px 30px;
  &__title {
    color: var(--title-color);
  }
  h3 {
    color: #a3a3a3;
  }
}

.login-form {
  color: var(--text-color);
  padding: 10px 30px 20px 30px;
  border-radius: 5px;
}

.login-input {
  width: 100%;
  margin: 5px 0;
  padding: 6px 10px;
  color: var(--text-color);
  border: none;
  border-bottom: 1px solid #04ccfc;
  outline: none;
}

.login-button {
  color: #ffffff;
  font-weight: bold;
  background-color: #47a3b9;
  padding: 8px 16px;
  border: #47a3b9;
  outline: none;
  margin: 10px 10px 10px 0;
  transition: background-color 250ms ease-in-out;
  text-transform: capitalize;
  border-radius: 15px;
  &:hover {
    background-color: var(--button-hover-color);
  }
}
.login-response {
  font-weight: bold;
  color: #b94747;
}
</style>
