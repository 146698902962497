<template>
  <div class="option-button">
    <button
      :style="disabled ? 'background-color: gray !important' : ''"
      :disabled="disabled"
      class="button"
    >
      {{ title }}
    </button>
  </div>
</template>

<script>
export default {
  name: "OptionButton",
  props: {
    title: {
      type: String,
      default: ""
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data: function() {
    return {
      buttonTitle: ""
    };
  }
};
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.option-button {
  margin: 0;
  display: inline-block;

  & > button.button {
    margin: 0;
    color: #ffffff;
    background-color: #47a3b9;
    font-weight: 400;
    font-size: 14px;
    line-height: normal;
    text-transform: capitalize;
    border: none;
    border-radius: 5px;
    padding: 16px 18px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 8px 0px,
      rgba(0, 0, 0, 0.19) 0px 6px 20px 0px;
    outline: none;

    &:enabled {
      transition: all 0.1s ease;

      &:hover {
        background-color: var(--button-hover-color);
        transform: scale(1.05);
      }
    }
  }
}
</style>
